//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dialogCard from './dialogCard.vue'
export default {
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    components: {
        dialogCard,
    },
    data() {
        return {
            cards: [
                {
                    title: '更周全',
                    text: '一站式覆蓋收文、發文、審批、標簽設置等功能',
                    url: '/images/dialogCardFirst.png',
                },
                {
                    title: '好上手',
                    text: '零成本上手，簡單易懂、看了就會的常規操作',
                    url: '/images/dialogCardSecond.png',
                },
                {
                    title: '可定制',
                    text: '我們提供了標準版，互聯版，以及私有化版本根據需要自由選擇',
                    url: '/images/dialogCardThree.png',
                },
            ],
        }
    },
}
